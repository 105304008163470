@import url("https://fonts.googleapis.com/css?family=Jost");

:root {
  --color-primary: #168dff;
  --color-secondary: #093866;
  --color-accent: #faa724;
  --color-accent-shade: #fbca85;
  --color-body: #2f2f2f;
  --color-background-body: var(--color-primary);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 67%;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-background-body);
  font-family: Jost, Arial, Helvetica, sans-serif;
  font-size: 2.4rem;
  line-height: 1.5;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

h1,
h2,
h3 {
}

h1 {
  font-size: 2.8rem !important;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem !important;
  font-weight: 400;
  margin-bottom: 0.3rem;
}

h3 {
  font-size: 1.7rem !important;
  font-weight: 400;
  margin-bottom: 0.3rem;
}

p {
  margin-top: 0;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 2.2rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
